import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, HostListener, Injectable, Input, Output, ViewChild} from '@angular/core';
import {MatTree, MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from "../../services/api.service";
import {TreeUnitNode} from "../../models/ct-unit.model";
import { TranslateService } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';


/**
 * Node for to-do item
 */
export class TreeNode {
  // @ts-ignore
  children: TreeNode[];
  // @ts-ignore
  item: TreeUnitNode;
}

/** Flat to-do item node with expandable and level information */
export class TreeFlatNode {
  // @ts-ignore
  item: TreeUnitNode;
  // @ts-ignore
  level: number;
  // @ts-ignore
  expandable: boolean;
}

/**
 * Checklist database, it can builds a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class UnitTreeService {
  dataChange = new BehaviorSubject<TreeNode[]>([]);


  get data(): TreeNode[] {
    return this.dataChange.value;
  }

  constructor(private _apiService: ApiService) {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.

    this._apiService.get('/assignment/units').subscribe({
      next: (res: any) => {
        console.log('/assignment/units res', res);

        const data: TreeNode[] = this.buildFileTree(res.data);

        this.dataChange.next(data);
      },
      error: (error: any) => {
        console.log("error getting /assignment/units", error);
      }
    });

    // Notify the change.

  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(nodes: TreeUnitNode[]): TreeNode[] {
    return nodes.reduce<TreeNode[]>((accumulator, key) => {
      const node = new TreeNode();
      node.item = key;

      if (key.children && key.children.length > 0) {
        node.children = this.buildFileTree(key.children);
      }

      return accumulator.concat(node);
    }, []);
  }

}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-cgi-destination-tree',
  templateUrl: './cgi-destination-tree.component.html',
  styleUrls: ['./cgi-destination-tree.component.scss'],
  providers: [UnitTreeService],
})
export class CgiDestinationTreeComponent {

  /**
   * Holds the current value of the slider
   */
  @Input() selectedDestination?: TreeUnitNode;

  /**
   * Holds the current value of the slider
   */
  @Input() initSelectUnitId?: number;

  /**
   * Invoked when the model has been changed
   */
  @Output() selectedDestinationChange: EventEmitter<TreeUnitNode> = new EventEmitter<TreeUnitNode>();

  @Output() selectedParentChange: EventEmitter<TreeFlatNode> = new EventEmitter<TreeFlatNode>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeFlatNode, TreeNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeFlatNode | undefined = undefined;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TreeFlatNode>;

  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

  isLoading = true;

  hideTree: boolean = false;

  narrowScreen: boolean = false;

  private readonly nodeNameLengthThreshold: number = 30;

  private readonly defaultLanguage: string;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeFlatNode>(true /* multiple */);

  @ViewChild(MatTree) matTree?: MatTree<TreeFlatNode>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateNarrowScreen();
  }

  constructor(
    private _database: UnitTreeService,
    private _translateService: TranslateService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.defaultLanguage = this._translateService.getDefaultLang();
    _database.dataChange.subscribe(data => {
      console.log('data', data);

      if(data.length > 0)
        this.isLoading = false;

      this.sortTreeNodesByName(data);

      this.dataSource.data = data;

      if(this.initSelectUnitId) {
        loop1:
          for (let l1 of data)
            for (let l2 of l1.children ?? [])
              for(let l3 of l2.children ?? [])
                if(this.initSelectUnitId == l3.item.id) {
                  this.selectedDestination = l3.item;
                  break loop1;
                }
      }

      if (this.dataSource.data.length && this.selectedDestination) {
        const selectedDestinationCorrespondingTreeFlatNode: TreeFlatNode | undefined = this.getCorrespondingFlatTreeNode(this.selectedDestination);
        if (selectedDestinationCorrespondingTreeFlatNode) {
          this.todoLeafItemSelectionToggle(selectedDestinationCorrespondingTreeFlatNode, true);
        }
      }

      this.updateNarrowScreen();
    });
  }

  private sortTreeNodesByName(array: TreeNode[]) {
    array.forEach((item) => {
      if (item.children?.length)
        this.sortTreeNodesByName(item.children);
    });
    array.sort((a, b) => {
      const aName = a.item.name;
      const bName = b.item.name;
      if (!aName && !bName) {
        return 0;
      } else if (!aName && bName) {
        return 1;
      } else if (aName && !bName) {
        return -1;
      } else if (aName && bName) {
        return aName.localeCompare(bName, this.defaultLanguage);
      }
      return 0;
    });
  }

  getLevel = (node: TreeFlatNode) => node.level;

  isExpandable = (node: TreeFlatNode) => node.expandable;

  getChildren = (node: TreeNode): TreeNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TreeFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  getCorrespondingFlatTreeNode(node: TreeUnitNode): TreeFlatNode | undefined {
    return this.treeControl.dataNodes.find(n => JSON.stringify(n.item) === JSON.stringify(node));
  }

  /*
    Check to see if there is only one leaf node available down the tree of the selected parent node.
    If so, select that leaf node.
  */
  parentNodeClicked(node: TreeFlatNode) {
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapseAll();
      this.treeControl.expand(node);
      let soleChild: TreeUnitNode | undefined = undefined;
      let currentNode: TreeUnitNode = node.item;
      findSoleChildNodeLoop: while (!soleChild) {
        switch ((currentNode.children ?? []).length) {
          case 0:
            // Perform this check due to the [disabled] condition in the HTML,
            // which prevents nodes with level < 2 to be selected.
            if (currentNode.level && !(currentNode.level < 2)) {
              soleChild = currentNode;
            } else {
              break findSoleChildNodeLoop;
            }
            break;
          case 1:
            currentNode = currentNode.children![0];
            break;
          default:
            const maybeCorrespondingFlatTreeNode = this.getCorrespondingFlatTreeNode(currentNode);
            if (maybeCorrespondingFlatTreeNode?.expandable) {
              // Expand all parent nodes if available and expandable.
              let parentNode = this.getParentNode(maybeCorrespondingFlatTreeNode);
              while (parentNode != undefined) {
                if (parentNode.expandable) {
                  this.treeControl.expand(parentNode);
                }
                parentNode = this.getParentNode(parentNode);
              }
              // Expand this node.
              this.treeControl.expand(maybeCorrespondingFlatTreeNode);
            }
            break findSoleChildNodeLoop;
        }
      }
      if (soleChild) {
        const correspondingFlatTreeNode = this.getCorrespondingFlatTreeNode(soleChild);
        if (correspondingFlatTreeNode) {
          this.todoLeafItemSelectionToggle(correspondingFlatTreeNode, true);
        }
      }
    }
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.clear();
    this.checklistSelection.select(node);
    this.treeControl.expand(node);

    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFlatNode, checked: boolean): void {
    this.checklistSelection.clear();

    if(checked) {
      this.selectedDestination = node.item;
      this.selectedDestinationChange.emit(this.selectedDestination);
      this.checklistSelection.select(node);
      this.checkAllParentsSelection(node);
      this.selectedParent = this.getParentNode(node);
      this.selectedParentChange.emit(this.selectedParent);
      this.hideTree = true;
    } else {
      this.selectedDestination = undefined;
      this.selectedDestinationChange.emit(undefined);
      this.selectedParent = undefined;
      this.selectedParentChange.emit(undefined);
    }

    let topParentNode: TreeFlatNode | undefined = this.getParentNode(node);
    while (topParentNode && topParentNode.level != 1) {
      topParentNode = this.getParentNode(topParentNode);
    }
    this.matTree?.treeControl.collapseAll();
    if (topParentNode) {
      this.matTree?.treeControl.expandDescendants(topParentNode);
    }
  }

  unHideTree() {
    this.hideTree = !this.hideTree;
    if (!this.hideTree) {
      this.checklistSelection.selected.forEach(s => {
        if(s.expandable) {
          this.treeControl.expand(s);
        }
      });
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFlatNode): void {
    let parent: TreeFlatNode | undefined = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    if (!nodeSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeFlatNode): TreeFlatNode | undefined {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return undefined;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return undefined;
  }

  updateNarrowScreen() {
    this.narrowScreen = window.innerHeight <= 1024;
  }

  showPartialNodeName(name: string | undefined): boolean {
    return this.narrowScreen && (name != undefined && name.length >= this.nodeNameLengthThreshold);
  }

  getMaybePartialNodeName(name: string | undefined): string | undefined {
    return (name && this.showPartialNodeName(name)) ? name.slice(0, this.nodeNameLengthThreshold) + "..." : name;
  }

  isOverflowing(ref: MatButton | HTMLDivElement | undefined, containerType: 'button' | 'checkbox') : boolean {
    if (ref === undefined) {
      return false;
    }
    const elementRef: HTMLElement = (containerType === 'button')
      ? (ref as MatButton)._elementRef.nativeElement
      : ref as HTMLDivElement;
    const labelContainer: HTMLElement | undefined | null = elementRef.querySelector('span.tree-node-text-label')?.parentElement;
    return (!!labelContainer) && (labelContainer.clientWidth < labelContainer.scrollWidth);
  }
}
