<h1 mat-dialog-title>{{'Save this search' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'Write the name for the search you want to save' | translate}}</p>
  <mat-form-field appearance="fill">
    <mat-label>{{'Search name' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.favoriteFilterGroupName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="data.favoriteFilterGroupName" cdkFocusInitial color="primary">{{'Save' | translate}}</button>
</div>
