import { AuthConfig } from 'angular-oauth2-oidc';
import {environment} from "../../../environments/environment";

export const authConfig: AuthConfig = {
  issuer: environment.oidcIssuer, //'https://auth.cgikna.se/realms/gitlabs',
  clientId: 'samtj-web',
  responseType: 'code',
  redirectUri: window.location.origin + '/index.html',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  scope: 'openid profile email offline_access roles samtj-api',
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  //timeoutFactor: 1, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
};
