import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {animate, state, style, transition, trigger} from "@angular/animations";


@Component({
  selector: 'app-pretty-details-dialog',
  templateUrl: 'pretty-details-dialog.component.html',
  styleUrls: ['pretty-details-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrettyDetailsDialogComponent {

  detail?: any;

  constructor(
    public dialogRef: MatDialogRef<PrettyDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

    this.detail = data.detail;

  }

}
