<h1 mat-dialog-title>{{'Confirm' | translate}}</h1>
<div mat-dialog-content>

  <span><strong>{{ 'Are you sure you want to resend' | translate }}</strong> #{{dicomJob?.id}}</span>
  <br>
  <br>
  <mat-slide-toggle *ngIf="isShowSelectOnlyResendErrors" class="m-b-20" [(ngModel)]="isOnlyResendErrors">{{'Only resend errors' | translate}}</mat-slide-toggle>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
  <button mat-raised-button color={{data.okButtonColor}} [mat-dialog-close]="{isOnlyResendErrors: isOnlyResendErrors}" cdkFocusInitial>{{'Yes' | translate}}</button>
</div>
