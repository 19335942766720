import {AfterViewInit, ContentChildren, Directive, ElementRef, OnDestroy, OnInit, QueryList} from '@angular/core';

@Directive({
  selector: '[appAdjustableColumnWidth]'
})
export class AdjustableColumnWidthDirective implements AfterViewInit, OnInit, OnDestroy {
    private resizeObserver: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.resizeTableColumns(entries.map(e => (e.target as HTMLElement)));
    });

    @ContentChildren('resizeColumn', {descendants: true}) 
    private resizeableColumnContainers?: QueryList<ElementRef>

    constructor(private tableRef: ElementRef) {
        this.tableRef.nativeElement.classList.add('table-adjustable-columns');
    }

    ngOnInit(): void {
        this.resizeObserver.disconnect();
    }

    ngAfterViewInit(): void {
        this.resizeableColumnContainers?.changes.subscribe((changes) => {
            this.resizeObserver.disconnect();
            // Listen for width/height changes for each column header.
            changes.forEach((element: ElementRef) => {
            this.resizeObserver.observe(element.nativeElement);
            });
        });
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
    }

    private resizeTableColumns(columnHeaders: HTMLElement[]): void {
        const getClosestParentOfType = (element: Element, type: string) => {
        let parentRow: ParentNode | undefined | null = element.parentNode;
        while (parentRow && (parentRow?.nodeName.toLowerCase() !== type.toLowerCase())) {
            parentRow = parentRow.parentNode;
        }
        return parentRow;
        }
        for (const entry of columnHeaders) {
        let parentRow = getClosestParentOfType(entry, 'tr');
        if (parentRow) {
            let columnIndex: number;
            let newColumnWidth: string;
            // Find index of column in row in order to find and resize corresponding cells in the table body.
            Array.from(parentRow.children).forEach((th, index) => {
            if (th.querySelector('div.resize-column') === entry) {
                columnIndex = index;
                newColumnWidth = `${entry.clientWidth}px`;
                const parentTable: ParentNode | null = getClosestParentOfType((parentRow as Element), 'table');
                if (parentTable) {
                const tableBody = parentTable.querySelector('tbody');
                if (tableBody) {
                    Array.from(tableBody.children).forEach((row) => {
                    const cellToResize: HTMLElement = (row.children[columnIndex]?.querySelector('div.resize-cell') as HTMLElement)
                    if (cellToResize)
                        cellToResize.style.width = newColumnWidth;
                    });
                }
                }
            }
            });
        }
        }
    }

    public updateColumnWidth() {
        if (this.resizeableColumnContainers) {
            this.resizeTableColumns(this.resizeableColumnContainers.toArray().map(c => c.nativeElement));
        }
    }
}
