<div fxLayout="row wrap" *ngIf="exportJob">
  <div fxFlex.gt-lg="75" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card appearance="outlined">
      <mat-card-content>

        <h3 *ngIf="exportJob?.identifier && exportJob.identifier !== ''"><strong>{{'identifier' | translate}}:</strong> {{exportJob.identifier}}</h3>
        <h4 *ngIf="exportJob?.patientId && exportJob.patientId !== ''"><strong>{{'patientId' | translate}}:</strong> {{exportJob.patientId}}</h4>

        <p><strong>{{'Validity start' | translate}}:</strong> {{exportJob.startTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p><strong>{{'Validity end' | translate}}:</strong> {{exportJob.endTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob.maxNrDownloads > 0"><strong>{{'maxNrDownloads' | translate}}:</strong> {{exportJob.maxNrDownloads}}</p>
        <p *ngIf="exportJob.maxNrDownloads > 0"><strong>{{'nrDownloads' | translate}}:</strong> {{exportJob.nrDownloads}}</p>



        <div fxLayout="row wrap" class="align-items-center" fxLayoutGap="16px grid">
          <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
            <div class="buttons">
              <button mat-raised-button color="primary" class="download-btn" *ngIf="exportJob?.status === 'fail' || exportJob?.status === 'new'  || exportJob?.status === 'deleted'" (click)="prepareExport()">{{'Prepare export' | translate}}</button>
              <button mat-raised-button color="primary" class="download-btn" *ngIf="exportJob.status === 'success' && exportJob.maxNrDownloads > 0" (click)="download()"><mat-icon>system_update_alt</mat-icon> {{'Download' | translate}}</button>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-content>
        <!--<h4>{{'Details' | translate}}</h4>-->

        <div class="buttons">
          <button mat-raised-button color="accent" (click)="showIncludedItems = !showIncludedItems">{{(showIncludedItems ? 'Hide details' : 'Show details') | translate}}</button>
          <button mat-raised-button (click)="navigator.clipboard.writeText(window.location.href)"><mat-icon>content_copy</mat-icon> {{'Copy share link to clipboard' | translate}}</button>
        </div>

      </mat-card-content>

      <mat-card-content  *ngIf="showIncludedItems" class="download-details">

        <button mat-raised-button *ngIf="exportJob?.status === 'success' && exportJob.maxNrDownloads > 0" (click)="prepareExport()">{{'Prepare export' | translate}}</button>

        <p><strong>{{'Id' | translate}}:</strong> {{exportJob.id}}</p>
        <p *ngIf="exportJob?.createdDate && exportJob.createdDate !== ''"><strong>{{'createdDate' | translate}}:</strong> {{exportJob.createdDate | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob?.createdBy && exportJob.createdBy !== ''"><strong>{{'createdBy' | translate}}:</strong> {{exportJob.createdBy}}</p>
        <p *ngIf="exportJob?.updatedDate && exportJob.updatedDate !== ''"><strong>{{'updatedDate' | translate}}:</strong> {{exportJob.updatedDate | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob?.errorMessage && exportJob.errorMessage !== ''"><strong>{{'errorMessage' | translate}}:</strong> {{exportJob.errorMessage}}</p>
        <!--<p *ngIf="exportJob?.destExportPath && exportJob.destExportPath !== ''"><strong>{{'destExportPath' | translate}}:</strong> {{exportJob.destExportPath}}</p>-->
        <p *ngIf="exportJob?.fileReadyTime && exportJob.fileReadyTime !== ''"><strong>{{'fileReadyTime' | translate}}:</strong> {{exportJob.fileReadyTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob?.fileDeletedTime && exportJob.fileDeletedTime !== ''"><strong>{{'fileDeletedTime' | translate}}:</strong> {{exportJob.fileDeletedTime | date: 'yyyy-MM-dd HH:mm'}}</p>

        <span>{{'Items included in download' | translate}}:</span>
        <mat-list class="example-list-wrapping">
          <mat-list-item *ngFor="let step of exportJob.exportJobSteps ?? []" class="included-item">
            <span matListItemTitle><strong>{{step.stepIdentifier}}</strong></span>
            <span matListItemLine *ngIf="step?.archive && step.archive !== ''"><strong>{{'Archive' | translate}}:</strong> {{step.archive}}</span>
            <span matListItemLine *ngIf="step?.stepMessage && step.stepMessage !== ''">
               <button mat-mini-fab color="primary" class="mdc-fab-mini-override" (click)="showDetails(step.stepMessage)">
                <mat-icon>visibility</mat-icon>
              </button>
              <strong>{{'stepMessage' | translate}}:</strong> {{step.stepMessage}}
            </span>
            <span matListItemLine *ngIf="step?.stepDir && step.stepDir !== ''"><strong>{{'stepDir' | translate}}:</strong>: {{step.stepDir}}</span>
          </mat-list-item>
        </mat-list>

      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex.gt-lg="25" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card appearance="outlined">
      <mat-card-content class="bg-dark">

        <p><strong>{{'Type' | translate | toFirstUpperCase}}:</strong> {{exportJob.type | toFirstUpperCase}}</p>
        <p><strong>{{'Format' | translate | toFirstUpperCase}}:</strong> {{exportJob.format | toFirstUpperCase}}</p>

        <h3 class="label-status" [ngClass]="{
        'bg-warning': exportJob?.status === 'new',
        'bg-danger': exportJob?.status === 'fail',
        'bg-primary': exportJob?.status === 'processing',
        'bg-success': exportJob?.status === 'success',
        'bg-info': exportJob?.status === 'deleted',
        }"
        >{{exportJob.status | translate | toFirstUpperCase}}</h3>
      </mat-card-content>
      <mat-card-content>
        <mat-progress-spinner *ngIf="exportJob.status !== 'success'"
          class="export-spinner"
          [color]="getSpinnerColor()"
          [mode]="getSpinnerMode()"
          [value]="getSpinnerValue()"> 100%
        </mat-progress-spinner>
      </mat-card-content>
    </mat-card>
  </div>
</div>
