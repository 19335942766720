import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, mergeMap, Observable, tap, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {ApiResponse} from "../models/response.model";
import {OAuthService} from "angular-oauth2-oidc";
import {v4 as uuidv4} from 'uuid';
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private _http: HttpClient, private _oauthService: OAuthService, private _toastService: ToastService) {}

  private isLoadingArrSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public isLoadingArr$: Observable<string[]> = this.isLoadingArrSubject$.asObservable();

  // POST
  post<T>(path: string, data?: object): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    return this.appendAuthorizationHeader().pipe(mergeMap(
      (header: { headers?: HttpHeaders}) => {
        return this._http
          .post<ApiResponse<T>>(
              environment.apiBaseUrl + path,
            data ? JSON.stringify(data) : data,
              header
            )
          .pipe(
            tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
            retry(1),
            catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
          );
      }
    ));
  }
  // GET
  get<T>(path: string): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    return this.appendAuthorizationHeader().pipe(mergeMap((header: { headers?: HttpHeaders}) => {

        // @ts-ignore
        return this._http
          .get<ApiResponse<T>>(environment.apiBaseUrl + path, header)
          .pipe(
            tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
            retry(1),
            catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
          );
      }
    ));
  }

  getWithCustomAccessToken<T>(path: string, accessToken: string): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    const header = this.SetAuthorizationHeader( accessToken );

    return this._http
      .get<ApiResponse<T>>(environment.apiBaseUrl + path, header)
      .pipe(
        tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
        retry(1),
        catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
      );
  }
  // PUT
  put<T>(path: string, data?: object): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    return this.appendAuthorizationHeader().pipe(mergeMap(
      (header: { headers?: HttpHeaders}) => {
        return this._http
          .put<ApiResponse<T>>(
            environment.apiBaseUrl + path,
            (data ? JSON.stringify(data) : data),
            header
          )
          .pipe(
            tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
            retry(1),
            catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
          );
      }
    ));
  }

  putWithCustomAccessToken<T>(path: string, accessToken: string, data?: object ): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    const header = this.SetAuthorizationHeader( accessToken );

    return this._http
          .put<ApiResponse<T>>(
            environment.apiBaseUrl + path,
            (data ? JSON.stringify(data) : data),
            header)
          .pipe(
            tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
            retry(1),
            catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
          );

  }
  // DELETE
  delete<T>(path: string): Observable<ApiResponse<T>> {

    const jobId = uuidv4();
    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

    return this.appendAuthorizationHeader().pipe(mergeMap(
      (header: { headers?: HttpHeaders}) => {
        return this._http
          .delete<ApiResponse<T>>(environment.apiBaseUrl + path, header)
          .pipe(
            tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) )),
            retry(1),
            catchError(err => this.errorHandl<T>(err, this._toastService, jobId))
          );
      }
    ));

  }

  // Error handling
  private errorHandl<T>(error: any, toast: ToastService, jobId: string): Observable<ApiResponse<T>> {
    let errorMessage = '';

    if (error?.error?.message) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    toast.openError(errorMessage, 'Stäng Felet');

    this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x != jobId) );

    return throwError(() => {
      return errorMessage;
    });
  }

  private appendAuthorizationHeader(): Observable<{ headers?: HttpHeaders}> {

    return new Observable((observer) => {

      let header = this.httpOptions;

      //console.log("this._oauthService.hasValidAccessToken()", this._oauthService.hasValidAccessToken());

      if (this._oauthService.hasValidAccessToken()) {

        header = this.SetAuthorizationHeader( this._oauthService.getAccessToken() );

        observer.next(header)
        observer.complete();

      } else {


        this._oauthService.refreshToken().then(t => {

          header = this.SetAuthorizationHeader(t.access_token);

          observer.next(header)
          observer.complete();

        }).catch(_ => {

          observer.next(header)
          observer.complete()
        });

      }

    });

  }


  private SetAuthorizationHeader(accessToken: string) {

    let header = this.httpOptions;

    const token = 'Bearer ' + accessToken;

    if(this.httpOptions.headers.has("Authorization")) {
      header.headers = this.httpOptions.headers.set("Authorization", token)
    } else {
      header.headers = this.httpOptions.headers.append('Authorization', token);
    }

    return header;

  }

}
