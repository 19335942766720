import {Pipe, PipeTransform} from "@angular/core";
import {orderBy} from "lodash";
import {KeyValue} from "../models/key-value.model";

@Pipe({
  name: "orderByKeyValue"
})
export class OrderByKeyValuePipe implements PipeTransform {
  transform(array: KeyValue<number, string>[]): KeyValue<number, string>[] {
    return array.sort((a: KeyValue<number, string>, b: KeyValue<number, string>) => a.value?.localeCompare(b?.value ?? "") ?? 0 ).filter(x => x.key !== undefined);
  }
}
