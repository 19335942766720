import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "isOverflowing",
  pure: false // Make pipe non-pure to run pipe on each detection cycle.
})
export class IsOverflowingPipe implements PipeTransform {
  transform(ref: HTMLElement | undefined): boolean {
    if (ref === undefined) {
      return false;
    }
    return ref?.scrollWidth > ref?.clientWidth;
  }
}
