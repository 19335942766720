import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "prettyDicomPatientName"
})
export class PrettyDicomPatientNamePipe implements PipeTransform {
  transform(value?: string): string {
    return value?.toString()?.replaceAll("^", " ") ?? "";
  }

}
