import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {availableExaminationColumns} from "../models/sp-examination.model";

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  // Http Headers

  constructor(private _translate: TranslateService, private _toastService: MatSnackBar) {}

  open(message: string, action?: string, config?: MatSnackBarConfig) {

    this._translate.get([message, 'Close']).subscribe(trans => {

      action = trans['Close'] ?? action;
      message = trans[message] ?? message;

      config ??= {
        duration: 12000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      };

      this._toastService.open(message, action, config);

    });

  }

  openSuccess(message: string, action?: string, config?: MatSnackBarConfig) {

    this._translate.get([message, 'Close']).subscribe(trans => {

      action = trans['Close'] ?? action;
      message = trans[message] ?? message;

      config ??= {
        duration: 12000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['snackbar-success']
      };

      this._toastService.open(message, action, config);

    });

  }

  openError(message: string, action?: string, config?: MatSnackBarConfig) {

    this._translate.get([message, 'Close']).subscribe(trans => {

      action = trans['Close'] ?? action;
      message = trans[message] ?? message;

      config ??= {
        duration: 12000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['snackbar-error'],
      };

      this._toastService.open(message, action, config);

    });

  }

}
