import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {ApiService} from "../../../shared/services/api.service";
import {TranslateService} from "@ngx-translate/core";
import {availableExaminationColumns} from "../../../shared/models/sp-examination.model";
import {availableSentExaminationColumns} from "../../../shared/models/sp-sent-examination.model";
import {UserService} from "../../../shared/services/user.service";
import {
    $UserHaveFunction,
  FunctionCollaboration,
  FunctionCollaborationAdmin,
    User,
    UserSetting,
    UserSettingDetail
} from "../../../shared/models/user.model";
import {SelectItem} from "../../../shared/models/select-item.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HelperService} from "../../../shared/services/helper.service";
import {ResponseSharedMetas} from "../../../shared/models/shared.model";
import {Theme, StateService} from 'src/app/shared/services/state.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnDestroy {
  //dropdownSettings?: IDropdownSettings;
  availableWorkListItems?: SelectItem[];
  selectedWorkListFilters: SelectItem[] = [];
  selectedWorkListColumns: SelectItem[] = [];
  availableWorkListSentItems?: SelectItem[];
  selectedWorkListSentColumns: SelectItem[] = [];
  selectedWorkListSentFilters: SelectItem[] = [];
  loadingUpdateProfile: boolean = false;

  authedUser: User | undefined;
  isAlwaysShowDetails: boolean = false;
  isAlwaysExpandFilters: boolean = false;
  isAlwaysCloseNavMenu: boolean = false;
  isCompressWorkListQuestionText: boolean = false;
  isHideTableDICONIcon: boolean = false;

  disposable: any;

  selectedTheme: string;

  private persistedTheme: string;


  constructor(private cdr: ChangeDetectorRef,
              private _apiService: ApiService,
              private translate: TranslateService,
              private _userService: UserService,
              private toastService: MatSnackBar,
              private _helperService: HelperService,
              private _stateService: StateService) {
    this.selectedTheme = this._stateService.currentTheme;
    this.persistedTheme = this._stateService.currentTheme;
  }

  ngOnInit() {

    const allAvailableItems = [
      ...availableExaminationColumns.map((x: any) => x.value),
      ...availableSentExaminationColumns.map((x: any) => x.value)]
      .filter((item, index, self) => self.indexOf(item) === index);

    this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
      this.authedUser = x;

      if(!this.authedUser?.userId)
        return;

      this.translate.get(allAvailableItems).subscribe(trans => {
        this.loadColumnData(trans);
      });

    });

  }
  loadColumnData(trans: any){
    this.availableWorkListItems = [];
    this.availableWorkListSentItems = [];

    //Välj visade filtreringsfält
    for (let c of availableExaminationColumns) {
      this.availableWorkListItems.push({item_id: c.value, item_text: trans[c.value], item_value: '', item_type: c.type})
    }

    //Välj visade filtreringsfält
    for (let c of availableSentExaminationColumns) {
      this.availableWorkListSentItems.push({item_id: c.value, item_text: trans[c.value], item_value: '', item_type: c.type})
    }

    const userDetails = this.authedUser?.userSettings?.userSettingDetails;

    this.selectedWorkListFilters = this._helperService.filterUserData(this.availableWorkListItems, userDetails?.filter(x => x.type == 'default_work_list_filters').map(x => x.value) );
    this.selectedWorkListColumns = this._helperService.filterUserData(this.availableWorkListItems, userDetails?.filter(x => x.type == 'default_work_list_columns').map(x => x.value) );
    this.selectedWorkListSentFilters = this._helperService.filterUserData(this.availableWorkListSentItems, userDetails?.filter(x => x.type == 'default_work_list_send_filters').map(x => x.value) );
    this.selectedWorkListSentColumns = this._helperService.filterUserData(this.availableWorkListSentItems, userDetails?.filter(x => x.type == 'default_work_list_send_columns').map(x => x.value) );

    const userIsShowDetails = userDetails?.filter(x => x.type == 'is_always_show_detail')[0];
    this.isAlwaysShowDetails = userIsShowDetails?.value == 'true' ?? false;
     //Behöver också sätta checkboxen

    this.isAlwaysExpandFilters = this._userService.getUserBooleanSetting('is_always_expand_filters');
    this.isAlwaysCloseNavMenu = this._userService.getUserBooleanSetting('is_always_close_nav_menu');
    this.isCompressWorkListQuestionText = this._userService.getUserBooleanSetting('is_compress_work_list_question_text');
    this.isHideTableDICONIcon = this._userService.getUserBooleanSetting('is_hide_table_DICON_icon');

    /*
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Välj inga',
      searchPlaceholderText: 'Sök...',
      //itemsShowLimit: 3,
      allowSearchFilter: true
    };

     */

  }

  updateProfile() {

    this.loadingUpdateProfile = true;


    const userSettingDetails = [
      ...this.selectedWorkListFilters.map(x => {
          return {
            type: 'default_work_list_filters',
            value: x.item_id,
          }
        }
      ),
      ...this.selectedWorkListColumns.map(x => {
          return {
            type: 'default_work_list_columns',
            value: x.item_id,
          }
        }
      ),
      ...this.selectedWorkListSentFilters.map(x => {
          return {
            type: 'default_work_list_send_filters',
            value: x.item_id,
          }
        }
      ),
      ...this.selectedWorkListSentColumns.map(x => {
          return {
            type: 'default_work_list_send_columns',
            value: x.item_id,
          }
        }
      ),
      {
        type: 'is_always_show_detail',
        value: this.isAlwaysShowDetails ? 'true' : 'false',
      },
      {
        type: 'is_always_expand_filters',
        value: this.isAlwaysExpandFilters ? 'true' : 'false',
      },
      {
        type: 'is_always_close_nav_menu',
        value: this.isAlwaysCloseNavMenu ? 'true' : 'false',
      },
      {
        type: 'is_compress_work_list_question_text',
        value: this.isCompressWorkListQuestionText ? 'true' : 'false',
      },
      {
        type: 'is_hide_table_DICON_icon',
        value: this.isHideTableDICONIcon ? 'true' : 'false',
      },
      {
        type: 'default_color_theme',
        value: this.selectedTheme,
      }
    ] as UserSettingDetail[]


    const userSetting: UserSetting = {
      username: this.authedUser?.username,
      userSettingDetails: userSettingDetails
    };

    this.checkIfItemsToRemove(userSetting);

  }

  checkIfItemsToRemove(userSetting: UserSetting) {

    const userDetails = this.authedUser?.userSettings?.userSettingDetails;
    const currentUserFilters = userDetails?.filter(x => x.type == 'default_work_list_filters');
    const currentUserColumns = userDetails?.filter(x => x.type == 'default_work_list_columns');
    const currentUserSendFilters = userDetails?.filter(x => x.type == 'default_work_list_send_filters');
    const currentUserSendColumns = userDetails?.filter(x => x.type == 'default_work_list_send_columns');

    const removedFilters = currentUserFilters?.filter(item => item?.value && this.selectedWorkListFilters.map(x => x.item_id).indexOf(item.value) < 0);
    const removedColumns = currentUserColumns?.filter(item => item?.value && this.selectedWorkListColumns.map(x => x.item_id).indexOf(item.value) < 0);
    const removedSendFilters = currentUserSendFilters?.filter(item => item?.value && this.selectedWorkListSentFilters.map(x => x.item_id).indexOf(item.value) < 0);
    const removedSendColumns = currentUserSendColumns?.filter(item => item?.value && this.selectedWorkListSentColumns.map(x => x.item_id).indexOf(item.value) < 0);

    const removeIds = [
      ...removedFilters?.map(x => x.id) || [],
      ...removedColumns?.map(x => x.id) || [],
      ...removedSendFilters?.map(x => x.id) || [],
      ...removedSendColumns?.map(x => x.id) || []
    ];

    if(removeIds.length > 0) {
      const removeParams = removeIds.join('&ids=');
      this._apiService.delete('/user/setting/details?ids=' + removeParams).subscribe({
        next: (res: any) => {
          console.log('/user/setting/details?' + removeParams, res);
          this.doUpdateProfile(userSetting);
        },
        error: (error: any) => {
          console.log("error examinations detail", error);
          this.loadingUpdateProfile = false;
        }
      });
    } else {
      this.doUpdateProfile(userSetting);
    }

  }

  doUpdateProfile(userSetting: UserSetting) {

    this._apiService.put('/user/setting', userSetting).subscribe({
      next: (res: any) => {
        console.log('/user/setting res', res);

        const data = res.data as UserSetting;
        this._userService.updateUserSettingDetails(data.userSettingDetails);
        this.translate.get("Ok profile update").subscribe(trans => {
          this.toastService.open(trans);
        });
        this.loadingUpdateProfile = false;
        this.persistedTheme = this._userService.getUserStringSetting('default_color_theme') || this.selectedTheme;
        // Save theme settings to local storage in order to load them before user settings
        // have been loaded via an API call. Relying solely on the API call to get the
        // theme setting will cause a noticeable delay before the theme setting is applied
        // when loading the application.
        localStorage.setItem(this._stateService.THEME_SETTING_LOCAL_STORAGE_KEY, this.persistedTheme);
      },
      error: (error: any) => {
        console.log("error examinations detail", error);
        this.loadingUpdateProfile = false;
      }
    });

  }


  populateConfig(config: string) {

    this._apiService.get('/referencedata/shared_data?type='+config+'&startPos=0&nrItems=200').subscribe({
      next: (res: any) => {
        console.log('/referencedata/shared_data?type=', res);
        const resp = res.data as ResponseSharedMetas;

        const data = resp.items;

        this.selectedWorkListFilters = this._helperService.filterUserData(this.availableWorkListItems, data?.filter(x => x.section == 'default_filters').map(x => x.key) );
        this.selectedWorkListColumns = this._helperService.filterUserData(this.availableWorkListItems, data?.filter(x => x.section == 'default_columns').map(x => x.key) );
        this.selectedWorkListSentFilters = this._helperService.filterUserData(this.availableWorkListSentItems, data?.filter(x => x.section == 'default_send_filters').map(x => x.key) );
        this.selectedWorkListSentColumns = this._helperService.filterUserData(this.availableWorkListSentItems, data?.filter(x => x.section == 'default_send_columns').map(x => x.key) );

      }
    });

  }

  changeTheme(newTheme: string) {
    if (newTheme == Theme.Light) {
      this._stateService.changeTheme(Theme.Light);
    } else if (newTheme == Theme.Dark) {
      this._stateService.changeTheme(Theme.Dark);
    }
  }

  ngOnDestroy() {
    if (this.selectedTheme != this.persistedTheme) {
      this.changeTheme(this.persistedTheme);
    }
    this.disposable.unsubscribe();
  }

  protected readonly FunctionCollaborationAdmin = FunctionCollaborationAdmin;

  protected readonly FunctionCollaboration = FunctionCollaboration;
    protected readonly $UserHaveFunction = $UserHaveFunction;
}
