import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {
  FunctionCollaboration,
  FunctionExportDicom,
  FunctionExportHl7, FunctionExportXds,
  User,
  UserSettingDetail
} from "../models/user.model";
import {ApiService} from "./api.service";
import {AuthService, OauthProfile} from "../auth/auth.service";
import {Router} from "@angular/router";
import * as _ from "lodash";


@Injectable({
    providedIn: 'root'
})
export class UserService {


    private userSubject$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
    public user$: Observable<User | undefined> = this.userSubject$.asObservable();


    constructor(
      private _authService: AuthService,
      private _apiService: ApiService,
      private _router: Router
    ) {

      this._authService.profile$.subscribe((x: OauthProfile | undefined) => {

        console.log("_authService.profile", x);

        this.getUserFromBackend(x)

      });


    }

    listenToAuthedUser(): Observable<User | undefined> {
        return this.user$;
    }

    setUser(user?: User | undefined, isOidcProfile = false): User | undefined {

      if(!this.userSubject$.value && isOidcProfile) {
        this.userSubject$.next(user);
        return user;
      }

      if(!_.isEqual(user, this.userSubject$.value) && !isOidcProfile)
          this.userSubject$.next(user);

        return user;
    }

    logOut(): void {
        this.setUser(undefined);
        this._authService.logout();
    }

  getUserFromBackend(idpUser?: OauthProfile | undefined) {

    console.log('idpUser', idpUser);

    if(!idpUser?.profile)
      return;

    let tempUser: User = {
      username: idpUser?.profile?.preferred_username,
      firstname: idpUser?.profile?.given_name,
      lastname: idpUser?.profile?.family_name,
      sid: idpUser?.profile?.sid,
      email: idpUser?.profile?.email
    };

    this.setUser(tempUser, true);

    this._apiService.get<User>("/user/me").subscribe({
      next: (res) => {
        console.log("idpUserRes", res);
        this.setUser(res.data);

        const stateUrl = idpUser?.stateUrl;
        const userFunctions = res.data.functions?.map(x => x.value);

        console.log("idpUser", idpUser);
        console.log("stateUrl", stateUrl);

        if(stateUrl && stateUrl == '/home' && userFunctions?.includes(FunctionCollaboration)) {
          this._router.navigateByUrl('/collaboration-portal/work-list');
          return;

        } else if (stateUrl && stateUrl == '/home' && (
            userFunctions?.includes(FunctionExportDicom) ||
            userFunctions?.includes(FunctionExportHl7) ||
            userFunctions?.includes(FunctionExportXds))) {
          this._router.navigateByUrl('/admin-portal/exports');
          return;

        } else if (stateUrl && stateUrl == '/home') {
          this._router.navigateByUrl('/');
          return;

        } else if (stateUrl) {
          this._router.navigateByUrl(stateUrl);
          return;
        }


      }, error: () => {
        this._router.navigateByUrl('/')
        return;
      }});
  }


    updateUserSettingDetails(newDetails: UserSettingDetail[] | null | undefined) {

      if(!newDetails)
        return

      let currentUser = this.userSubject$.value;

      if(currentUser?.userSettings)
        currentUser.userSettings.userSettingDetails = newDetails;

      this.setUser(currentUser);

    }

  getUserBooleanSetting(settingKey: string): boolean {
    const userDetails = this.userSubject$.value?.userSettings?.userSettingDetails;
    const settingKeyResults = userDetails?.filter(x => x.type == settingKey);
    return (settingKeyResults && settingKeyResults.length) ? (settingKeyResults[settingKeyResults.length - 1].value == 'true' ?? false) : false;
  }

  getUserStringSetting(settingKey: string): string | undefined {
    const userDetails = this.userSubject$.value?.userSettings?.userSettingDetails;
    const settingKeyResults = userDetails?.filter(x => x.type == settingKey);
    return (settingKeyResults && settingKeyResults.length) ? (settingKeyResults[settingKeyResults.length - 1].value) : undefined;
  }
}
