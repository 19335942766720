import {Injectable} from '@angular/core';
import {User, UserSettingDetail} from "../models/user.model";
import {SelectItem} from "../models/select-item.model";
import {SpExaminationDetailsSearch} from "../models/sp-examination.model";
import {SpExaminationsSent} from "../models/sp-sent-examination.model";
import {PriorExaminationsDto} from "../models/examinations.model";
import {MatTableDataSource} from "@angular/material/table";
import {CheckDicomImageDto} from "../models/check-dto.model";
import {DicomGroupClaimDto, DicomGroupDto, EaDicomGroupDto} from "../models/dicom-device.model";
import {ResponseRequestOverview} from "../models/request-overview.model";
import {BehaviorSubject, Observable} from "rxjs";

export type Nullable<T> = T | null;

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const DATEPICKER_FORMATS2 = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface MultiselectDiff<T> {
  removed: T[];
  new: T[]
}

export interface PatientsExaminations {
  patients: string[],
  patientsExaminations: object
}

export interface DetailSpExamination<T> {
  key: string,
  dataSource: MatTableDataSource<T>,
  foundDicomImages?: CheckDicomImageDto[]
}

export interface ResponseRequestOverviewSearch {
  searchString: string,
  data?: ResponseRequestOverview,
}

@Injectable({
    providedIn: 'root'
})
export class HelperService {

  requestOverviewData?: ResponseRequestOverviewSearch;

  public sessionPageSizeSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(20);
  public sessionPageSize$: Observable<number> = this.sessionPageSizeSubject$.asObservable();

  constructor() {}

  filterByUserData(availableArr?: SelectItem[], userDefault?: (UserSettingDetail | null | undefined)[] | null) {

    let filtered: SelectItem[] = [];
    for(let a of availableArr || []) {

      for(let d of userDefault || []) {
        if(a.item_id == d?.value) {
          filtered.push({
            item_id: a.item_id,
            item_text: a.item_text,
            item_type: a.item_type,
            item_value: a.item_value,
            db_id: d.id
          })
        }
      }
    }

    return filtered.sort((a, b) => (filtered?.map(x => x.item_id).indexOf(a.item_id) || 0) - (filtered?.map(x => x.item_id).indexOf(b.item_id) || 0)) || [];
  }

  filterUserData(availableArr?: SelectItem[], userDefaultArr?: (string | null | undefined)[] | null): SelectItem[] {
    return availableArr?.filter(x =>
      userDefaultArr?.includes(x.item_id)).sort((a, b) => (userDefaultArr?.indexOf(a.item_id) || 0) - (userDefaultArr?.indexOf(b.item_id) || 0)) || [];
  }

  getDiffs<T>(origArr?: T[], selectedItems?: SelectItem[], idKeyName: string = 'value', metaNewObject?: T): MultiselectDiff<T> {

    if(!origArr || !selectedItems)
      return {new: [], removed: []};

    // @ts-ignore
    const removed = origArr.filter(item => item[idKeyName]?.toString() && selectedItems.map(x => x.item_id).indexOf(item[idKeyName].toString()) < 0);

    // @ts-ignore
    const newSelectedItems= selectedItems.filter(s => origArr.map(x => x[idKeyName].toString()).indexOf(s.item_id) < 0);

    let newSelectedItemsMapped: T[] = [];
    for(let n of newSelectedItems) {
      let data: any = {};
      data[idKeyName] = Number(n.item_id);
      newSelectedItemsMapped.push(Object.assign(data, metaNewObject));
    }

    return {
      new: newSelectedItemsMapped,
      removed: removed
    }

  }

  makeListDistinct(data: any[], distinctBy: string): any[] {
    return  [...new Map(data.map(item => [item[distinctBy], item])).values()];
  }

  getPatientsExaminations(examinations: SpExaminationDetailsSearch[] | SpExaminationsSent[] | PriorExaminationsDto[]): PatientsExaminations {

    // @ts-ignore
    const foundPatients = examinations.reduce(function (r, a) {
      // @ts-ignore
      r[a.patientId] = r[a.patientId] || [];
      // @ts-ignore
      r[a.patientId].push(a);
      return r;
    }, Object.create(null));

    const patArr = Object.keys(foundPatients);

    return {
      patients: patArr,
      patientsExaminations: foundPatients
    }

  }


  updateOrPushToArr<T>(obj: DetailSpExamination<T>, arr: { key: string, dataSource: MatTableDataSource<T> }[]): { key: string, dataSource: MatTableDataSource<T> }[] {
    const objIndex = arr.findIndex(inArray => inArray.key == obj.key);
    if (objIndex > -1) {
      arr[objIndex] = obj;
    } else {
      arr.push(obj);
    }
    return arr;
  }


  parseDicomGroups(selectedGroups: SelectItem[], eaDicomGroups: EaDicomGroupDto[]): DicomGroupDto[] {
    let foundSelectedGroups: DicomGroupDto[] = [];

    selectedGroups.forEach(item => {
      const foundGroup = eaDicomGroups.find(x =>  x.name+':'+x.archiveName == item.item_id);

      if(foundGroup) {
        foundSelectedGroups.push({
          name: foundGroup.name,
          archiveName: foundGroup.archiveName,
          claims: foundGroup.claims?.map(x => {
            return {
              claimType: x.claimType,
              resource: x.resource
            } as DicomGroupClaimDto
          }),
          members: foundGroup.members,
          everyone: foundGroup.everyone
        });
      }

    });

    return foundSelectedGroups;
  }

  mapDicomGroupsToSelectedItems(dicomGroups?: EaDicomGroupDto[] | DicomGroupDto[]): SelectItem[] {
    return dicomGroups?.map(x => {
      return {
        item_id: x.name+':'+x.archiveName,
        item_text: x.name+':'+x.archiveName,
        item_tooltip_text: x.claims?.map(x => x.claimType).join(', ')
      }
    }) ?? []
  }

  isNumber(value?: string | number): boolean
  {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

}
