import {ChangeDetectorRef, Component, OnDestroy, Renderer2} from '@angular/core';
import {User} from "../../../../shared/models/user.model";
import {ApiService} from "../../../../shared/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../../shared/services/user.service";
import {ActivatedRoute} from "@angular/router";
import {DicomStudyDto} from "../../../../shared/models/dicom-study.model";
import {ToastService} from "../../../../shared/services/toast.service";
import {ExportJobDto, ResponseExportDownload} from "../../../../shared/models/exports.model";
import {interval, Subscription, timeout} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {filter, switchMap, take} from "rxjs/operators";
import {
  StringDetailsDialogComponent
} from "../../../../shared/dialog/string-details-dialog/string-details-dialog.component";

@Component({
  selector: 'app-exports-download',
  templateUrl: './exports-download.component.html',
  styleUrls: ['./exports-download.component.scss']
})
export class ExportsDownloadComponent implements OnDestroy {

  authedUser: User | undefined;

  disposable: any;

  exportJob?: ExportJobDto

  accessToken?: string;

  showIncludedItems = false;

  private timerSub?: Subscription;

  private routeSub?: Subscription;

  constructor(private _cdr: ChangeDetectorRef,
              private _renderer: Renderer2,
              private _apiService: ApiService,
              private _dialog: MatDialog,
              private _translate: TranslateService,
              private _userService: UserService,
              private _toastService: ToastService,
              private _route: ActivatedRoute) {
  }

  ngOnInit() {

    this.routeSub = this._route.queryParams.subscribe(params => {
      console.log(params) //log the entire params object

      this.accessToken = params['key'];

      this.loadDownloadData();

    });


    this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
      this.authedUser = x;

      if(!this.authedUser?.userId)
        return;

    });

  }

  showDetails(item: DicomStudyDto | ExportJobDto | string) {

    const enterAnimationDuration = '100ms';
    const exitAnimationDuration = '100ms';

    const dialogRef = this._dialog.open(StringDetailsDialogComponent, {
      width: '80%',
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '100ms',
      data: {
        detail: item,
        isCode: true
      }
    });

    /*
    const dialogRef = this._dialog.open(PrettyDetailsDialogComponent, {
      width: '80%',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        detail: item,
      }
    });
     */

    dialogRef.afterClosed().subscribe(selectedExaminationsObj => {

      if (!selectedExaminationsObj)
        return

    });

  }

  private loadDownloadData() {

    this._cdr.detectChanges();

    const uri = `/export/single`;

    this._apiService.getWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? "").subscribe({
      next: (res) => {
        console.log("res.data",  res.data);

        this.exportJob = res.data;

        if(this.exportJob.status == "processing")
          this.pollForDone();

      },
      error: (error: any) => {
        console.log("error examinations", error);
      }});



  }

  ngOnDestroy() {
    this.disposable.unsubscribe();
    this.routeSub?.unsubscribe();
    this.timerSub?.unsubscribe();
  }


  prepareExport() {

    const uri = `/export/prepare`;

    if(!this.exportJob)
      return;

    this.exportJob.status = 'processing';

    this._apiService.putWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? "").subscribe({
      next: (res) => {
        console.log("res.data", res.data);

        this.exportJob = res.data;

        this.pollForDone();

        },
      error: (error: any) => {

        if(this.exportJob)
          this.exportJob.status = 'fail';

        console.log("error examinations", error);
      }});

  }


  pollForDone() {

    const uri = `/export/single`;

    if(this.timerSub)
      return;

    // Create an Observable that emits every 5 seconds
    this.timerSub = interval(5000).pipe(

      // Since the httpClient returns an Observable, wrap it with a switchMap
      switchMap(() => this._apiService.getWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? "")),

      // Filter only the successful http responses
      filter((data) => {

        console.log("poll res.data", data.data);

        this.exportJob = data.data;

        return data.data.status === 'success' || data.data.status === 'fail' || data.data.status === 'new' || data.data.status === 'deleted';
      }),
      // Emit only the first value emitted by the source
      take(1),

      // Time out after 1 hour
      timeout(3600000),
    ).subscribe({
      next: (res) => {
        console.log("pollForDone res.data", res.data);

        this.exportJob = res.data;

      },
      error: (error: any) => {
        console.log("error examinations", error);
      }});

  }

  download() {

    const uri = `/export/download`;

    this._apiService.getWithCustomAccessToken(uri, this.accessToken ?? "").subscribe({
      next: (res: any) => {
        const data = res.data as ResponseExportDownload;
        console.log("res.data", data);

        const link = this._renderer.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', `${ environment.apiBaseUrl}/export/download/file?oneTimeKey=${data.oneTimeDownloadKey}`);
        link.setAttribute('download', `${ data.fileName}`);
        link.click();
        link.remove();

      },
      error: (error: any) => {
        console.log("error examinations", error);
      }});

  }

  getSpinnerColor(): 'accent' | 'primary' | 'warn' {

    if(this.exportJob?.status == 'fail')
      return 'warn';

    if(this.exportJob?.status == 'processing')
      return 'primary';

    return 'accent';
  }

  getSpinnerMode(): 'determinate' | 'indeterminate' {

    if(this.exportJob?.status == 'processing')
      return 'indeterminate';

    return 'determinate';
  }

  getSpinnerValue(): number {
    if(this.exportJob?.status == 'new')
      return 50;

    return 100;
  }

  protected readonly navigator = navigator;
  protected readonly window = window;

}
