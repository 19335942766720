<mat-card appearance="outlined" *ngIf="authedUser">
  <mat-card-content class="home-wrapper">

    <h1>{{'HOME_HEADING' | translate}}</h1>

    <p>{{'You are logged in as' | translate}} {{authedUser.firstname}} {{authedUser.lastname}}</p>
    <mat-button-toggle-group [(ngModel)]="showArea" name="theme" aria-label="Theme selection">
      <mat-button-toggle *ngIf="showArea !== 'none'" [value]="'none'">{{'Hide' | translate}}</mat-button-toggle>
      <mat-button-toggle [value]="'roles'">{{'View your Roles' | translate}}</mat-button-toggle>
      <mat-button-toggle [value]="'profile'">{{'View more of your profile' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill *ngIf="showArea === 'roles'">

      <div fxFlex="25">

        <h3>{{'HOME_ROLES_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.roles | keyvalue">
            <!--<span matListItemMeta>{{role.value['key']}}</span>-->
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>
      <div fxFlex="25">

        <h3>{{'HOME_ARCHIVES_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.archives | keyvalue">
            <!--<span matListItemMeta>{{role.value['key']}}</span>-->
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>
      <div fxFlex="25">

        <h3>{{'HOME_SENDINGSYSTEMS_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.systems | keyvalue">
            <!--<span matListItemMeta>{{role.value['key']}}</span>-->
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>

      <div fxFlex="25">

        <h3>{{'HOME_FUNCTIONS_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.functions | keyvalue">
            <!--<span matListItemMeta>{{role.value['key']}}</span>-->
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>

    </div>

    <!--<pre *ngIf="showArea === 'profile'">{{GetUserData() | json}}</pre>-->

    <div *ngIf="showArea === 'profile'" [innerHtml]="GetUserData() | toPrettyHtmlPipe" class="m-t-20"></div>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="!authedUser">
  <mat-card-content>
    <p>{{'HOME_NOT_AUTHENTICATED' | translate}}..</p>
    <p>{{'HOME_NOT_AUTHENTICATED_ADDITIONAL' | translate}}</p>
  </mat-card-content>
</mat-card>
