import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "toYesNo"
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean | string): string {
    const valueAsString: string = value.toString();

    console.log("valueAsString", valueAsString);

    switch(valueAsString.toLowerCase()) {
      case "true":
        return "Yes";
      case "false":
        return "No";
      default:
        return valueAsString;

    }
  }
}
