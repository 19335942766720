import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "toFirstUpperCase"
})
export class FirstUpperCasePipe implements PipeTransform {
  transform(value: string): string {
    const stringValue = value.toString();
    return stringValue.length ? (stringValue[0].toUpperCase() + stringValue.slice(1)) : stringValue;
  }
}
