import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TreeUnitNode } from '../models/ct-unit.model';
import { SpExaminationDetailsSearch } from '../models/sp-examination.model';
import { ExaminationAndPriors } from 'src/app/pages/collaboration-portal/work-list/work-list.component';
import { SelectItem } from '../models/select-item.model';

@Injectable({
    providedIn: 'root'
})
export class StateService {

  private themeChangedSource = new Subject<Theme>();
  private examinationDestinationChangedSource = new Subject<TreeUnitNode>();
  private commentMessageTemplateChangedSource = new Subject<string>();
  private workListStateChangedSource = new Subject<WorkListState | undefined>();

  
  // Default theme to light theme.
  private _currentTheme: Theme = Theme.Light;
  private _currentExaminationDestination: TreeUnitNode | undefined = undefined;
  private _currentCommentMessageTemplate: string | undefined = undefined;
  private _workListState: WorkListState | undefined = undefined;

  public readonly THEME_SETTING_LOCAL_STORAGE_KEY: string = 'theme-setting';

  public themeChanged$ = this.themeChangedSource.asObservable();
  public examinationDestinationChanged$ = this.examinationDestinationChangedSource.asObservable();
  public selectedCommentMessageTemplateChanged$ = this.commentMessageTemplateChangedSource.asObservable();
  public workListStateChanged$ = this.workListStateChangedSource.asObservable();

  changeTheme(theme: Theme) {
    this._currentTheme = theme;
    this.themeChangedSource.next(theme);
  }

  get currentTheme(): Theme {
    return this._currentTheme;
  }

  changeExaminationDestination(destination: TreeUnitNode) {
    this._currentExaminationDestination = destination;
    this.examinationDestinationChangedSource.next(destination);
  }

  get currentExaminationDestination(): TreeUnitNode | undefined {
    return this._currentExaminationDestination;
  }

  changeCommentMessageTemplate(messageTemplate: string) {
    this._currentCommentMessageTemplate = messageTemplate;
    this.commentMessageTemplateChangedSource.next(messageTemplate);
  }

  get currentCommentMessageTemplate(): string | undefined {
    return this._currentCommentMessageTemplate;
  }

  changeWorkListState(workListState: WorkListState | undefined) {
    this._workListState = workListState;
    this.workListStateChangedSource.next(workListState);
  }

  get currentWorkListState(): WorkListState | undefined {
    return this._workListState;
  }
}

export enum Theme {
  Light = 'light',
  Dark = 'dark'
}

export interface WorkListState {
  targetExam: SpExaminationDetailsSearch | undefined,
  selectedExaminations: ExaminationAndPriors[],
  comment?: string,
  selectedFilters: SelectItem[] | undefined,
  paginationPageSize: number | undefined,
  paginationPageIndex: number | undefined
}