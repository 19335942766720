<button *ngIf="!authedUser" mat-button color="Accent" class="m-r-5" (click)="clickedLogin()">{{'Log in' | translate}}</button>
<span class="text-primary-contrast font-10" *ngIf="authedUser && authedUser.healthCareUnitHsaId && authedUser.healthCareUnitHsaId !== ''">{{authedUser.healthCareUnitName}} ({{authedUser.healthCareUnitHsaId}})</span>
<!--<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
  <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
</button>
-->

<!--
<button mat-icon-button (click)="doZoom('out')">
  <mat-icon>zoom_out</mat-icon>
</button>

<button mat-icon-button (click)="doZoom('in')">
  <mat-icon>zoom_in</mat-icon>
</button>
-->

<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <i class="flag-icon rounded-flag flag-icon-{{ getIcon(currentLang) }} m-r-10"></i>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of languages" [value]="lang.code" (click)="changeLanguage(lang)">
    <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
    <span>{{ lang.language }}</span>
  </button>
</mat-menu>

<!--
<mat-form-field>
  <mat-select [value]="currentLang">
    <mat-option *ngFor="let lang of languages" [value]="lang.code" (click)="changeLanguage(lang)">
      <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
      <span>{{ lang.language }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
-->
<!--
<mat-menu #flags="matMenu" class="mymegamenu">
  <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
    <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
    <span>{{ lang.language }}</span>
  </button>
</mat-menu>
-->
  <!-- ============================================================== -->
  <!-- Notification - style you can find in header.scss -->
  <!-- ============================================================== -->
  <!--<button *ngIf="authedUser" [matMenuTriggerFor]="notification" mat-icon-button class="m-r-5">
    <mat-icon>message</mat-icon>
    <div class="notify">
      <span class="heartbit"></span>
      <span class="point"></span>
    </div>
  </button>
  <mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
      <ul>
        <li>
          <div class="drop-title text-muted">Notifications</div>
        </li>
        <li>
          <div class="message-center">
            <perfect-scrollbar [config]="config">
              <!- Message ->
              <a href="#" *ngFor="let notification of notifications">
                <div class="round {{ notification.round }}">
                  <i class="{{ notification.icon }}"></i>
                </div>
                <div class="mail-content">
                  <h5>{{ notification.title }}</h5>
                  <span class="mail-desc text-muted">{{ notification.subject }}</span>
                  <span class="time text-muted">{{ notification.time }}</span>
                </div>
              </a>
            </perfect-scrollbar>
          </div>
        </li>
      </ul>
    </div>
  </mat-menu>-->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
  <!--<button *ngIf="authedUser" [matMenuTriggerFor]="message" mat-icon-button class="m-r-5">
    <mat-icon>email</mat-icon>
    <div class="notify">
      <span class="heartbit"></span>
      <span class="point"></span>
    </div>
  </button>
  <mat-menu #message="matMenu" class="mymessage">
    <div class="mailbox">
      <ul>
        <li>
          <div class="drop-title text-muted">You have 4 new messages</div>
        </li>
        <li>
          <div class="message-center">
            <perfect-scrollbar [config]="config">
              <!- Message ->
              <a href="#" *ngFor="let mymessage of mymessages">
                <div class="user-img">
                  <img src="{{ mymessage.useravatar }}" alt="user" class="img-circle" width="40" />
                  <span class="profile-status {{ mymessage.status }} pull-right"></span>
                </div>
                <div class="mail-content">
                  <h5>{{ mymessage.from }}</h5>
                  <span class="mail-desc text-muted">{{ mymessage.subject }}</span>
                  <span class="time text-muted">{{ mymessage.time }}</span>
                </div>
              </a>
              <!- Message ->
            </perfect-scrollbar>
          </div>
        </li>
      </ul>
    </div>
  </mat-menu> -->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
<!--
  <button *ngIf="authedUser" [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img src="assets/images/users/1.jpg" alt="user" class="profile-pic" />
  </button>
  <mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item><mat-icon>settings</mat-icon> Settings</button>
    <button mat-menu-item><mat-icon>account_box</mat-icon> Profile</button>
    <button mat-menu-item><mat-icon>notifications_off</mat-icon> Disable notifications</button>
    <button mat-menu-item (click)="signOut($event)">><mat-icon>exit_to_app</mat-icon> Logga ut </button>
  </mat-menu>-->


