import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Observable, Observer, Subscription} from "rxjs";
import {AsyncPipe} from "@angular/common";
import {map} from "rxjs/operators";

@Pipe({
  name: "toPrettyHtmlPipe",
  pure: false
})
export class PrettyHtmlPipe implements PipeTransform, OnDestroy {

  private asyncPipe: AsyncPipe;

  constructor(private injector: Injector, private _translate: TranslateService) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }

  transform(object?: any): string {

    if(!object)
      return '';

    return this.asyncPipe.transform(this.transformObject(object)) ?? '';
  }

  transformObject(object: any): Observable<string> {

    let keys = this.getKeys(object);

    if (!this.isDefined(keys) || !keys.length) {
      keys = ['default'];
    }

    return this._translate.get(keys).pipe(
        map((translations) => this.convertObjectToHtmlString(translations, object) ?? '')
    );

  }

  isDefined(value: any) {
    return typeof value !== 'undefined' && value !== null;
  }

  private getKeys(object: any): string[] {

    let keys: string[] = [];

    for (const [key, value] of Object.entries(object)) {

      if(Array.isArray(value) || value === Object(value)) {
        keys = keys.concat(this.getKeys(value));
      } else {
        keys.push(key)
      }
    }

    return keys.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });

  }


  private convertObjectToHtmlString(translations: any, object: any): string {

    let html = '<div class="pretty-html-wrapper">';

    for (const [key, value] of Object.entries(object)) {

      if(Array.isArray(value) || value === Object(value)) {
        html += '<div class="pretty-html-nested m-l-40">';

        if(!isNaN(Number(key))) {
          html += `<span class="key">${Number(key)+1}: </span>`;
        } else {
          html += `<span class="key">${translations[key] ? translations[key] : key}: </span>`;
        }
        html += this.convertObjectToHtmlString(translations, value);
        html += '</div>';

      } else {
        if(value) {
          if(!isNaN(Number(key))) {
            html += `<p class="m-l-40"><span class="key">${Number(key)+1}: </span> <strong class="value">${value}</strong></p>`;
          } else {
            html += `<p class="m-l-40"><span class="key">${translations[key] ? translations[key] : key}: </span> <strong class="value">${value}</strong></p>`;
          }
        }
      }
    }

    html += '</div>';

    return html;

  }
}
