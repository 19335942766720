import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DicomJobDto} from "../../models/dicom-job.model";

@Component({
  selector: 'app-dicom-job-resend-dialog',
  templateUrl: 'dicom-job-resend-dialog.component.html',
  styleUrls: ['dicom-job-resend-dialog.component.scss'],
})
export class DicomJobResendDialogComponent {

  dicomJob?: DicomJobDto;
  isShowSelectOnlyResendErrors = false;
  isOnlyResendErrors = false;

  constructor(
    public dialogRef: MatDialogRef<DicomJobResendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dicomJob = data.dicomJob;

    const foundErrorSteps = this.dicomJob?.dicomJobSteps?.filter(x => x.cstatus != 'success')

    const isSeriesOrInstanceLevel = foundErrorSteps?.filter(x => x.seriesInstanceUid && x.seriesInstanceUid != '')

    if(isSeriesOrInstanceLevel && isSeriesOrInstanceLevel.length > 0)
      this.isShowSelectOnlyResendErrors = true;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
