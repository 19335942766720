import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../../shared/services/user.service";
import {User} from "../../../shared/models/user.model";
import {AuthService} from "../../../shared/auth/auth.service";

@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: [],
})
export class VerticalAppHeaderComponent {

  // This is for Notifications
  // tslint:disable-next-line - Disables all
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM',
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM',
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM',
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  // This is for Mymessages
  // tslint:disable-next-line - Disables all
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM',
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  /*
  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',
  };*/

  currentLang: 'sv' | 'en' = 'sv';

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us',
    },
    {
      language: 'Swedish',
      code: 'sv',
      type: 'SE',
      icon: 'se',
    }
  ];

  zoom = 100;

  authedUser: User | undefined;

  constructor(private _cdr: ChangeDetectorRef,
              private translate: TranslateService,
              private _userService: UserService,
              private _authService: AuthService) {


    this.translate.use(this.currentLang);

    this._userService.listenToAuthedUser().subscribe(x => {
      this.authedUser = x;
    });



  }

  changeLanguage(lang: any): void {
    this._cdr.detectChanges();

    console.log('lang', lang);
    this.currentLang = lang.code;
    this.translate.setDefaultLang(lang.code);
    this.translate.use(lang.code);
    //this.selectedLanguage = lang;
  }

  signOut($event: MouseEvent) {
    this._authService.logout();
  }

  clickedLogin() {
    this._authService.login();
  }

  getIcon(currentLang: "sv" | "en") {
    return this.languages.find(x => x.code == currentLang)?.icon;
  }

  doZoom(direction: 'in' | 'out') {

    if(direction == 'out' && this.zoom > 20)
      this.zoom = this.zoom - 20;

    if(direction == 'in')
      this.zoom = this.zoom + 20;

    // @ts-ignore
    document.body.style.zoom = this.zoom + "%";
  }
}
