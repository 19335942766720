<mat-card appearance="outlined" class="cgi-card">
  <mat-card-content>

    <div *ngIf="availableWorkListItems && availableWorkListSentItems && $UserHaveFunction(authedUser, [FunctionCollaboration, FunctionCollaborationAdmin])">

      <h2>{{'Your default filters and columns' | translate}}</h2>

      <div class="buttons">
        <button mat-raised-button color="primary" (click)="populateConfig('config_1')">{{'Config 1' | translate}}</button>
        <!--<button mat-raised-button color="primary" (click)="populateConfig('config_2')">{{'Config 2' | translate}}</button>-->
        <button mat-raised-button color="primary" (click)="populateConfig('config_3')">{{'Config 3' | translate}}</button>
      </div>

      <mat-divider class="m-t-10" inset="false"></mat-divider>

      <h3>{{'Work list' | translate}}</h3>

      <h4>{{'Filters' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list filters' | translate"
                       [(dataValue)]="availableWorkListItems"
                       [(selectedItems)]="selectedWorkListFilters">
      </app-cgi-multiselect-drag>


      <h4>{{'Columns' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list columns' | translate"
                       [(dataValue)]="availableWorkListItems"
                       [(selectedItems)]="selectedWorkListColumns">
      </app-cgi-multiselect-drag>

      <div class="boolConfigurationsContainer">
        <mat-checkbox id="alwaysShowDetails" [(ngModel)]="isAlwaysShowDetails">{{'Always show details' | translate}}</mat-checkbox>
        <mat-checkbox id="alwaysShowFilters" [(ngModel)]="isAlwaysExpandFilters">{{'Always expand filters' | translate}}</mat-checkbox>
        <mat-checkbox id="alwaysCloseNavMenu" [(ngModel)]="isAlwaysCloseNavMenu">{{'Always close the navigation menu' | translate}}</mat-checkbox>
        <mat-checkbox id="compressWorkListQuestionText" [(ngModel)]="isCompressWorkListQuestionText">{{'Compress question text' | translate}}</mat-checkbox>
        <mat-checkbox id="hideTableDICONIcon" [(ngModel)]="isHideTableDICONIcon">{{'Hide DICON icon in table' | translate}}</mat-checkbox>
      </div>

      <mat-divider class="m-t-20"></mat-divider>

      <h3>{{'Sent List' | translate}}</h3>

      <h4>{{'sent filters' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list filters' | translate"
                       [(dataValue)]="availableWorkListSentItems"
                       [(selectedItems)]="selectedWorkListSentFilters">
      </app-cgi-multiselect-drag>

      <h4>{{'sent columns' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select sent list columns' | translate"
                       [(dataValue)]="availableWorkListSentItems"
                       [(selectedItems)]="selectedWorkListSentColumns">
      </app-cgi-multiselect-drag>

      <div class="boolConfigurationsContainer">
        <mat-checkbox id="compressSentListTable" [(ngModel)]="isCompressSentListTable">{{'Compress table' | translate}}</mat-checkbox>
      </div>

    </div>

    <mat-divider class="m-t-20"></mat-divider>
    <h4>{{'Theme' | translate}}</h4>
    <mat-button-toggle-group [disabled]="loadingUpdateProfile" (change)="changeTheme($event.value)" [(ngModel)]="selectedTheme" name="theme" aria-label="Theme selection">
      <mat-button-toggle value="light">{{'Light' | translate}}</mat-button-toggle>
      <mat-button-toggle value="dark">{{'Dark' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="m-t-20">
      <button mat-raised-button class="mat-primary" [class.spinner]="loadingUpdateProfile" [disabled]="loadingUpdateProfile" (click)="updateProfile()">{{'Update your profile' | translate}}</button>
    </div>

  </mat-card-content>

</mat-card>
